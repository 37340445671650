import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./rejectedModal.scss";
import { AuthServices } from "../../../services/AuthServices";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { docUploadRefreshContext } from "../contexts/CaseContexts";
import UploadDocumentModal from "./uploadDocumentModal";
import { logOut } from "../../../config/user";
import { CaseServices } from "../../../services/CaseServices";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "720px",
  height: "620px",
  borderRadius: "16px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  overflowY: "scroll",
  p: 4,
};

export default function RejectedModal({ documentDetails }) {
  const refreshFunction = React.useContext(docUploadRefreshContext);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const fileInputRef = React.useRef(null);
  const [docUrl, setDocUrl] = React.useState(null);
  const [documentsUrl, setDocumentsUrl] = React.useState(
    documentDetails.documenturlmasters
  );
  const [selectedDocument, setSelectedDocument] = React.useState({});
  const [modified, setModified] = React.useState(0);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const uploadImage = async (e) => {
    try {
      let data = new FormData();
      data.append("image", e.target.files[0]);
      let res = await AuthServices.uploadImage(data);
      setDocUrl(res.data.url);
      setModified(modified + 1);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert("Error occurred while uploading image");
      }
    }
  };

  const saveUrl = async () => {
    try {
      await CaseServices.saveDocumentUrl(documentDetails.id, documentsUrl);
      window.alert("Document saved");
      setDocumentsUrl([]);
      refreshFunction.getMappedDocuments();
      handleClose();
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };

  React.useEffect(() => {
    if (docUrl) {
      const formattedData = documentsUrl?.map((document) => {
        if (document.id === selectedDocument.id) {
          let newDocument = {
            ...document,
            documentUrl: docUrl,
            urlId: document.id,
          };
          return newDocument;
        } else {
          return document;
        }
      });
      setDocumentsUrl(formattedData);
    }
  }, [docUrl]);
  return (
    <div className="rejected-modal-container">
      <div className="btn-container" onClick={handleOpen}>
        <ErrorOutlineOutlinedIcon sx={{ height: "16px", color: "#CF3636" }} />
        <div className="text-container">
          {documentDetails.rejectionReason}
          <button className="text-btn" onClick={handleOpen}>
            Read More
          </button>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="custom-modal"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "Inter, sans-serif",
              alignItems: "center",
              justifyContent: "center",
              // height: "100%",
            }}
          >
            <input
              type="file"
              onChange={(e) => uploadImage(e)}
              style={{ display: "none" }}
              ref={fileInputRef}
              accept=".png,.jpg,.jpeg"
            />
            <div
              style={{
                fontFamily: "inherit",
                fontSize: "24px",
                fontWeight: "600",
                color: "#191919",
              }}
            >
              {documentDetails?.documentName}
            </div>
            <div
              style={{
                fontFamily: "inherit",
                fontSize: "16px",
                fontWeight: "400",
                color: "#797979",
                marginTop: "16px",
              }}
            >
              {documentDetails?.description}
            </div>
            <div
              style={{
                height: "24px",
                borderRadius: "24px",
                width: "128px",
                backgroundColor: "#FFD6DB",
                color: "#CF3636",
                fontFamily: "inherit",
                fontSize: "12px",
                fontWeight: "600",
                border: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              {documentDetails?.status}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "60px",
                width: "65%",
                backgroundColor: "#F9F9F9",
                color: "#797979",
                fontFamily: "inherit",
                fontSize: "14px",
                fontWeight: "400",
                padding: "8px",
                gap: "8px",
                borderRadius: "8px",
              }}
            >
              <ErrorOutlineOutlinedIcon
                sx={{ height: "16px", color: "#CF3636" }}
              />
              {documentDetails?.rejectionReason}
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontFamily: "inherit",
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#797979",
                  marginTop: "16px",
                  width: "65%",
                }}
              >
                <div className="flex justify-end items-center gap-2">
                  Add More{" "}
                  <UploadDocumentModal
                    documentDetails={documentDetails}
                    sequencePrev={documentsUrl?.length}
                  />
                </div>
                {documentsUrl?.map((document, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{ border: "1px solid #E4E4E4" }}
                      className="flex justify-between items-center p-4 w-[100%] mt-2 rounded-[16px] bg-[#F9F9F9]"
                    >
                      <div className="w-[50px] h-[50px]">
                        <img
                          src={document.documentUrl}
                          width={50}
                          height={50}
                          alt="img-icons"
                        />
                      </div>
                      <div>
                        {document?.status?.toLowerCase().includes("reject") && (
                          <div
                            className="w-fit flex gap-2 items-center px-5 py-1 bg-[#017C57] rounded-full text-white cursor-pointer"
                            onClick={() => {
                              setSelectedDocument(document);
                              handleButtonClick();
                            }}
                          >
                            <FileUploadOutlinedIcon fontSize="small" />{" "}
                            <div>Re-Upload</div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <button
                style={{
                  height: "34px",
                  width: "160px",
                  borderRadius: "32px",
                  marginTop: "64px",
                  fontFamily: "Inter, sans-serif",
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  backgroundColor: "white",
                  color: "#017C57",
                  border: "1px solid #017C57",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleClose}
              >
                <SettingsBackupRestoreOutlinedIcon sx={{ height: "18px" }} />{" "}
                BACK
              </button>

              <button
                style={{
                  height: "34px",
                  width: "160px",
                  borderRadius: "32px",
                  marginTop: "64px",
                  fontFamily: "Inter, sans-serif",
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "12px",
                  backgroundColor: "white",
                  color: "#017C57",
                  border: "1px solid #017C57",
                }}
                onClick={() =>
                  modified > 0
                    ? saveUrl()
                    : window.alert("Please Re-upload documents")
                }
              >
                SAVE
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
